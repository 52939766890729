@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.container, .main-menu__menu, .header__content {
  max-width: 80rem;
  margin: auto;
}
@media screen and (max-width: 80rem) {
  .container, .main-menu__menu, .header__content {
    padding: 0 20px;
  }
}

.container--v1, .main-menu__menu--v1, .header__content--v1 {
  min-width: 769px;
  max-width: 1280px;
  padding: 20px;
  margin: auto;
}

*:before, *:after, * {
  box-sizing: border-box;
}

.colour-likes {
  color: #d617e0;
}

.colour-shares {
  color: #25CCCf;
}

.colour-comments {
  color: #5E6172;
}

.colour-visits {
  color: #FF3190;
}

.colour-unique-visitors {
  color: #FFD200;
}

.colour-repeat-visits {
  color: #E28413;
}

.colour-hard-bounce {
  color: #ef3e36;
}

.colour-soft-bounce {
  color: #962727;
}

.colour-multi-page {
  color: #470606;
}

.color-red {
  color: #f04c4c;
}

.color-green {
  color: #642f86;
}

.color-mid-grey {
  color: #989898;
}

.header-bar-background-colour {
  background: linear-gradient(270deg, #d617e0 0%, #642f86 90%);
  height: 112px;
  width: 100%;
}

.disable-swatch {
  position: relative;
  top: -6px;
  left: -2px;
}

.disable-swatch:before {
  position: absolute;
  left: 15px;
  content: " ";
  height: 38px;
  width: 2px;
  border-radius: 10px;
  background-color: black;
}

.disable-swatch:before {
  transform: rotate(45deg);
}

body, input, textarea, a {
  color: #5E6172;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.text-large, .report-comparison__title, .report-data__total, .report-builder__preview-no-content, .report-builder__preview-content h2, .report-builder__header h1 {
  font-size: 21px;
  line-height: 28px;
}

strong, b {
  font-weight: 700;
}

em {
  font-style: italic;
}

code {
  background-color: #E6EBED;
}

.margin-small, .channel-keys__title, .report-wrap__type {
  margin-bottom: 10px;
}

.margin-medium, .report-type-selector, .report-wrap__icon, .new-user-welcome__title {
  margin-bottom: 20px;
}

.margin-large, .overlay__subnav {
  margin-bottom: 30px;
}

.margin-xlarge, .channel-keys, .report-wrap__introduction, .report-wrap__title {
  margin-bottom: 40px;
}

.no-margin {
  margin-bottom: 0;
}

.text-small, .report-builder__help, .report-data__label {
  font-size: 13px;
  line-height: 18px;
}

.text-ms {
  font-size: 15px;
  line-height: 18px;
}

.text-medium, .visitor-card__detail h3 {
  font-size: 22px;
  line-height: 25px;
}

.text-large, .report-comparison__title, .report-data__total, .report-builder__preview-no-content, .report-builder__preview-content h2, .report-builder__header h1 {
  font-size: 22px;
  line-height: 25px;
}

.text-xlarge {
  font-size: 30px;
  line-height: 39px;
}

.text-uppercase, .report-data__label {
  text-transform: uppercase;
}

.text-add-underline {
  cursor: pointer;
  text-decoration: underline;
}

/**
    Checkbox
*/
.checkbox {
  display: inline-grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 6px;
  align-items: center;
  cursor: pointer;
  text-align: left;
}
.checkbox input {
  display: none;
}
.checkbox.disabled {
  opacity: 0.5;
}
.checkbox .checkbox__switch {
  border: 2px solid #989898;
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 6px;
  position: relative;
}
.checkbox .checkbox__switch:after {
  display: block;
  content: "";
  width: 14px;
  height: 14px;
  margin: 2px;
}
.checkbox input:checked ~ .checkbox__switch {
  border: 2px solid #642f86;
}
.checkbox input:checked ~ .checkbox__switch:after {
  display: block;
  content: "";
  width: 14px;
  height: 14px;
  margin: 2px;
  background-color: #642f86;
  border-radius: 4px;
}
.col-two {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.col-three {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.col-four {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.col-five {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.col-six {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.col-two-thirds {
  display: grid;
  grid-template-columns: 33.3333fr 66.6666fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.col-two-filled {
  display: grid;
  grid-template-columns: 50fr 25fr 25fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.middle-ver {
  align-items: center;
}

.middle-hor {
  justify-content: center;
}

.dropdown--no-click {
  border: 1px solid transparent;
  background-color: #ffffff;
  padding: 4px 12px;
  border-radius: 4px;
}

.dropdown__menu.dark {
  background-color: #5E6172;
}
.dropdown__menu.dark:after {
  border-bottom: 5px solid #5E6172;
}
.dropdown__menu.dark .dropdown__item--add {
  color: #FFD200;
}
.dropdown__menu.dark .dropdown__item:hover {
  background-color: #474956;
}
.dropdown__menu.light {
  background-color: #ffffff;
}
.dropdown__menu.light:after {
  border-bottom: 5px solid #ffffff;
}
.dropdown__menu.light .dropdown__item--add {
  color: #642f86 !important;
}
.dropdown__menu.light .dropdown__item {
  color: #5E6172;
}
.dropdown__menu.light .dropdown__item:hover {
  background-color: #E6EBED;
}

.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown.dark .dropdown__title {
  background-color: #5E6172;
  background-image: url("https://s3.eu-west-1.amazonaws.com/beacon.assets/publicapp/assets/arrow-down-light.svg");
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown.dark .dropdown__title:hover {
  background-color: #474956;
}
.dropdown.dark .dropdown__menu {
  background-color: #5E6172;
}
.dropdown.dark .dropdown__menu:after {
  border-bottom: 5px solid #5E6172;
}
.dropdown.dark .dropdown__menu--empty {
  color: #ffffff;
}
.dropdown.dark .dropdown__item:hover {
  background-color: #474956;
}
.dropdown.dark .dropdown__item--add {
  color: #FFD200;
}
.dropdown.light .dropdown__title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: #ffffff;
  background-image: url("https://s3.eu-west-1.amazonaws.com/beacon.assets/publicapp/assets/arrow-down-dark.svg");
  color: #5E6172;
}
.dropdown.light .dropdown__title:hover {
  background-color: #E6EBED;
}
.dropdown.light .dropdown__menu {
  background-color: #ffffff;
}
.dropdown.light .dropdown__menu:after {
  border-bottom: 5px solid #ffffff;
}
.dropdown.light .dropdown__menu--empty {
  color: #5E6172;
}
.dropdown.light .dropdown__item--add {
  color: #642f86 !important;
}
.dropdown.light .dropdown__item {
  color: #5E6172;
}
.dropdown.light .dropdown__item:hover {
  background-color: #E6EBED;
}

.dropdown--disabled {
  pointer-events: none;
  opacity: 0.7;
}

.dropdown--tiny * {
  font-size: 10px;
}
.dropdown--tiny .dropdown__title {
  padding: 2px 30px 2px 8px;
}
.dropdown--tiny .dropdown__menu {
  margin-top: 4px;
}
.dropdown--tiny .dropdown__clear {
  padding: 1px 10px 3px 10px !important;
  line-height: 1.7 !important;
}

.dropdown__title {
  border: 1px solid transparent;
  padding: 4px 58px 4px 12px;
  border-radius: 4px;
  cursor: pointer;
  background-size: 11px;
  background-repeat: no-repeat;
  background-position: center right 12px;
  transition: background-color 0.1s linear;
}
.dropdown__title.is-clearing {
  margin-left: 30px;
  border-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.dropdown__menu {
  display: inline-block;
  position: relative;
  z-index: 6;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  min-width: 200px;
  max-height: 300px;
  overflow-y: auto;
  text-align: left;
}
.dropdown__menu.left {
  left: 0;
}
.dropdown__menu.left:after {
  left: 12px;
}
.dropdown__menu.right {
  right: 0;
}
.dropdown__menu.right:after {
  right: 12px;
}
.dropdown__menu.top {
  transform: translate(0, -140px);
}
.dropdown__menu:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: -5px;
  border-bottom: 5px solid #5E6172;
}

.dropdown__item {
  padding: 3px 6px;
  color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  transition: background-color 0.1s linear;
  position: relative;
  font-size: 14px;
}
.dropdown__item:hover {
  background-color: #474956;
}
.dropdown__item.has-icon {
  padding-left: 28px;
}

.dropdown__item--add .fa-plus-circle {
  margin-right: 6px;
}

.dropdown__clear {
  position: absolute;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #474956 !important;
  padding: 5px 8px !important;
  color: #ffffff !important;
}

.dropdown__icon {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 6px;
}

.reset-button, .report-type-selector__button, .shortlink-new__copy, .tag__remove, .form__password-eye, .logo-upload__button, .button, .report-list__create, .report-builder__generate, .report-campaign__remove, .font-awesome-picker__button, .social-account-connect li, .publish-link__post, .publish-link__short-url, .composer__date input, .dropdown__clear {
  cursor: pointer;
  text-align: inherit;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  text-decoration: none;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  outline: none;
}

.button, .report-list__create, .report-builder__generate, .report-campaign__remove, .font-awesome-picker__button, .social-account-connect li, .publish-link__post, .publish-link__short-url, .composer__date input {
  display: inline-block;
  border-radius: 4px;
  padding: 4px 14px;
  transition: all 0.1s linear;
  border: 1px solid transparent;
  text-align: center;
  font-size: 13px;
}
.button.disabled, .disabled.report-list__create, .disabled.report-builder__generate, .disabled.report-campaign__remove, .disabled.font-awesome-picker__button, .social-account-connect li.disabled, .disabled.publish-link__post, .disabled.publish-link__short-url, .composer__date input.disabled {
  color: #5E6172;
  cursor: default;
  background-color: #989898 !important;
  opacity: 0.7;
}
.button[disabled], [disabled].report-list__create, [disabled].report-builder__generate, [disabled].report-campaign__remove, [disabled].font-awesome-picker__button, .social-account-connect li[disabled], [disabled].publish-link__post, [disabled].publish-link__short-url, .composer__date input[disabled] {
  color: #5E6172;
  cursor: default;
  background-color: #989898 !important;
  opacity: 0.7;
}
.button:hover, .report-list__create:hover, .report-builder__generate:hover, .report-campaign__remove:hover, .font-awesome-picker__button:hover, .social-account-connect li:hover, .publish-link__post:hover, .publish-link__short-url:hover, .composer__date input:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.button--large, .report-builder__generate, .social-account-connect li {
  font-size: 17px;
  padding: 7px 14px;
  font-weight: 300;
}

.button--small, .report-campaign__remove {
  font-size: 12px;
  padding: 2px 9px;
}

.button--purple {
  color: #ffffff;
  background-color: #2E294E;
}

.button--pink {
  color: #ffffff;
  background-color: #FF3190;
}

.button--yellow, .publish-link__post, .publish-link__short-url {
  color: #3b3d48;
  background-color: #FFD200;
}
.button--yellow:hover, .publish-link__post:hover, .publish-link__short-url:hover {
  background-color: #f0c500;
}

.button--blue {
  color: #ffffff;
  background-color: #d617e0;
}
.button--blue.hollow {
  color: #d617e0;
  background-color: transparent;
  border: 1px solid #d617e0;
}

.button--green, .report-list__create, .report-builder__generate {
  color: #231f20;
  background-color: #ffd200;
  font-weight: 700;
}

.button--red, .report-campaign__remove {
  color: #ffffff;
  background-color: #f04c4c;
}

.button--dark-grey {
  color: #ffffff;
  background-color: #5E6172;
}
.button--dark-grey.hollow {
  color: #5E6172;
  background-color: transparent;
  border: 1px solid #5E6172;
}

.button--mid-grey {
  color: #ffffff;
  background-color: #989898;
}
.button--mid-grey.hollow {
  color: #989898;
  background-color: transparent;
  border: 1px solid #989898;
}

.button--light-grey {
  color: #5E6172;
  background-color: #E6EBED;
}
.button--light-grey.hollow {
  background-color: transparent;
  border: 1px solid #E6EBED;
}

.button--white {
  background-color: #ffffff;
}
.button--white.hollow {
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff;
}

.button--no-border {
  border: none !important;
}
.button--no-border:hover {
  box-shadow: none;
}

.button--transparent {
  background-color: transparent;
}

.button--has-icon {
  position: relative;
  padding-left: 45px;
  border: 0;
}

.button__icon {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  height: 100%;
  width: 35px;
  display: grid;
  align-items: center;
}

.button__ad-network-connect--google {
  background: url("https://s3.eu-west-1.amazonaws.com/beacon.assets/publicapp/assets/btn_google_signin.png") -5px -117px no-repeat;
  width: 191px;
  height: 46px;
  border: none;
  position: relative;
  left: -3px;
}
.button__ad-network-connect--google:hover:not([disabled]) {
  cursor: pointer;
}
.button__ad-network-connect--google:focus {
  background-position: -5px -61px;
}
.button__ad-network-connect--google:active {
  background-position: -5px -173px;
}
.button__ad-network-connect--google[disabled] {
  background-position: -5px -5px;
}

.button__ad-network-connect--facebook {
  background: #1877F2;
  color: white;
  border: none;
  display: flex;
  border-radius: 5px;
  align-items: center;
}
.button__ad-network-connect--facebook:before {
  content: " ";
  background: url("https://s3.eu-west-1.amazonaws.com/beacon.assets/publicapp/assets/f_logo_RGB-White_58.png");
  background-size: contain;
  display: block;
  height: 24px;
  width: 24px;
  margin: 8px 0;
}
.button__ad-network-connect--facebook:after {
  content: "Continue with Facebook";
  padding: 0 3px 0 15px;
  font-weight: bold;
  font-family: "Arial", sans-serif;
  font-size: 14px;
}
.button__ad-network-connect--facebook:hover:not([disabled]) {
  cursor: pointer;
}
.button__ad-network-connect--facebook:active {
  background: #0a49a0;
}
.button__ad-network-connect--facebook[disabled] {
  background: #b3b3b3;
}

.external {
  background-color: #d617e0;
  background: linear-gradient(270deg, #d617e0 0%, #662d91 90%);
  min-height: 100vh;
  display: grid;
  align-content: center;
  justify-items: center;
}

.external__logout {
  position: absolute;
  top: 30px;
  right: 35px;
  color: #ffffff;
  cursor: pointer;
}

.login {
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto max-content;
}

.login__content {
  align-self: center;
}

.login__footer {
  padding-bottom: 50px;
  text-align: center;
}

.login__logo {
  display: block;
  margin: 0 auto 40px auto;
  width: 340px;
}

.login__title {
  text-align: center;
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 50px;
  font-weight: 300;
  display: none;
}

.login__form {
  width: 300px;
  margin: auto;
}
.login__form .form__label {
  color: #ffffff;
}
.login__form .form__input, .login__form .shortlink-existing__input, .login__form .shortlink-new__input {
  border: 0;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  font-size: 18px;
  padding: 12px 16px;
  font-weight: 300;
}
.login__form .form__input::placeholder, .login__form .shortlink-existing__input::placeholder, .login__form .shortlink-new__input::placeholder {
  color: rgba(255, 255, 255, 0.35);
}
.login__form .form__input:focus, .login__form .shortlink-existing__input:focus, .login__form .shortlink-new__input:focus {
  outline: none;
  border: 0;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.15);
}
.login__form .button, .login__form .report-list__create, .login__form .report-builder__generate, .login__form .report-campaign__remove, .login__form .font-awesome-picker__button, .login__form .social-account-connect li, .social-account-connect .login__form li, .login__form .publish-link__post, .login__form .publish-link__short-url, .login__form .composer__date input, .composer__date .login__form input {
  display: block;
  width: 100%;
  font-size: 18px;
  padding: 12px 16px;
  font-weight: 300;
}

.login__error {
  background-color: #ffffff;
  padding: 20px 20px 20px 90px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.error-icon {
  color: #FF3190;
  font-size: 48px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.login__links li {
  color: #ffffff;
  display: inline-block;
}
.login__links li:after {
  content: "\2022";
  display: inline-block;
  color: #ffffff;
  padding: 0 15px;
}
.login__links li:last-child:after {
  padding: 0;
  content: "";
}
.login__links li a {
  color: #ffffff;
  display: inline-block;
}

.login-select {
  padding: 80px 0;
}

.login-select__intro {
  font-size: 28px;
  margin-bottom: 60px;
  font-weight: 300;
  text-align: center;
}

.login-select__company {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 15px;
}

.login-select__item {
  padding: 10px;
  cursor: pointer;
  font-weight: 300;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  transition: all 0.1s linear;
  background-color: rgba(255, 255, 255, 0.1);
}
.login-select__item:hover {
  background-color: rgba(255, 255, 255, 0.25);
}
.login-select__item:last-child {
  margin-bottom: 30px;
}

.card, .report-wrap__option, .export-campaigns__item {
  border: 1px solid #E6EBED;
  position: relative;
  background-color: #ffffff;
  border-radius: 5px;
}
.card.link, .link.report-wrap__option, .link.export-campaigns__item {
  position: relative;
  text-decoration: none;
  top: 0;
  transition: all 0.075s linear;
}
.card.link:hover, .link.report-wrap__option:hover, .link.export-campaigns__item:hover {
  top: -3px;
}

@media screen and (min-width: 1451px) {
  .card-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}
@media screen and (max-width: 1450px) {
  .card-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}
@media screen and (max-width: 900px) {
  .card-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}
.card--overflow {
  overflow-y: auto;
}

.card__header {
  padding: 20px 20px 0 20px;
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
}

.card__title {
  font-size: 18.5px;
  color: #989898;
}
.card__title a {
  font-size: 18.5px;
  color: #989898;
}

.card__help {
  margin-right: -5px;
}

.card__highlight {
  font-size: 42px;
  line-height: 48px;
}

.card__symbol {
  font-size: 22px;
  line-height: 0;
}

.card__highlight--smaller {
  font-size: 23px;
  line-height: 27px;
}

.card__content, .export-campaigns__item {
  padding: 20px;
}

.card--is-link, .report-wrap__option {
  cursor: pointer;
  display: block;
  text-decoration: none;
  transition: all 0.2s;
}
.card--is-link:hover, .report-wrap__option:hover {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
}

.card--has-border {
  border: 0;
  border-bottom-style: solid;
  border-width: 4px;
}

.post {
  display: grid;
  grid-template-rows: max-content auto max-content max-content;
  cursor: pointer;
  padding: 15px;
  border: 1px solid #E6EBED;
  border-radius: 4px;
  background-color: #ffffff;
  transition: all 0.2s linear;
  text-decoration: none;
  color: inherit;
}
.post:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.post.preview {
  cursor: default;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.post__origin {
  align-self: start;
  display: grid;
  grid-template-columns: max-content max-content auto;
  align-items: center;
  margin-bottom: 15px;
}

.post__avatar {
  width: 40px;
  height: auto;
  border-radius: 20px;
}

.post__network {
  display: grid;
  justify-items: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 18px;
  width: 18px;
  border-radius: 9px;
  border: 1px solid #ffffff;
}
.post__network.twitter {
  background-color: #28AAE1;
}
.post__network.facebook {
  background-color: #3B5999;
}
.post__network.linkedin {
  background-color: #0077B5;
}

.post__logo {
  color: #ffffff;
  font-size: 9px;
}

.post__username {
  padding-left: 15px;
}

.post__time {
  justify-self: end;
  color: #989898;
  font-size: 12px;
}

.post__content {
  margin-bottom: 20px;
  white-space: pre-wrap;
}

.post__links {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 10px;
}

.post__analytics {
  color: #989898;
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
}

.post__campaign {
  color: #989898;
  font-size: 12px;
  padding-top: 15px;
}

.summary {
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: relative;
  border: 1px solid #E6EBED;
}

.summary__header {
  border-bottom: 1px solid #E6EBED;
  padding-bottom: 5px;
}

.summary--post {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 40px;
}

.summary__title {
  font-size: 18px;
  margin-bottom: 20px;
}

.summary__title--icon {
  margin-right: 7px;
}

.summary__close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.summary__greeting {
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 15px;
  font-weight: 300;
}

.summary__points {
  list-style-type: square;
  padding-left: 20px;
}
.summary__points li {
  margin-bottom: 5px;
}
.summary__points li:last-child {
  margin-bottom: 0;
}

.stats__label {
  color: #989898;
}

.stats__value {
  font-size: 18px;
  line-height: 24px;
}
.stats__value a {
  text-decoration: none;
}
.stats__value a:hover {
  text-decoration: underline;
}
.stats__value.purple {
  color: #2E294E;
}
.stats__value.pink {
  color: #FF3190;
}
.stats__value.green {
  color: #642f86;
}

.stats__secondary-value {
  font-size: 12px;
  line-height: 16px;
}

.header {
  background-color: #5E6172;
  background: #5E6172;
  float: left;
  width: 14%;
  height: 100%;
  position: fixed;
  max-width: 280px;
  min-width: 215px;
  z-index: 4;
  display: flex;
  flex-flow: column;
}

.header__content {
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
}

.header__menu {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  grid-column-gap: 20px;
  align-items: center;
  justify-send: end;
}

.header__company {
  margin-right: -4px;
  padding: 5px 18px 5px 12px;
  box-shadow: inset -6px 0 4px rgba(0, 0, 0, 0.175);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: inline-block;
  background-color: #E6EBED;
}

.main-content--title {
  font-weight: 700;
  margin-top: 10px;
  font-size: 26px;
}

.main-content--v1 {
  width: 100%;
}

.feedback-banner {
  width: 100%;
  height: 30px;
  background-color: #fc85ae;
  text-align: center;
  padding: 5px;
  color: white;
  cursor: pointer;
}
.feedback-banner:hover {
  text-decoration: underline;
}

.header-collapse {
  position: absolute;
  /* color: white; */
  background-color: white;
  right: -10px;
  top: 3%;
  font-size: 20px;
  z-index: 12;
  border: 0.5px solid #E6EBED;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.header__small-header {
  display: none;
  width: 100%;
  height: 45px;
  background-color: #5E6172;
}

.header__small-header-icon {
  font-size: 25px;
  color: white;
  padding: 10px;
}

.header__small-link-creator {
  justify-self: end;
  display: grid;
  padding: 5px;
  grid-template-columns: auto max-content max-content;
  grid-column-gap: 30px;
}

.header__expand {
  width: 75px;
  min-width: auto;
}
.header__expand .main-menu__link {
  display: none;
}
.header__expand .main-menu__item {
  margin-left: 0;
  text-align: center;
}
.header__expand .main-menu__icon {
  padding: 15px;
  margin-right: 0;
}
.header__expand .main-menu__toolbar {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: none;
}
.header__expand .main-menu__toolbar-item {
  font-size: 100%;
  padding: 10%;
}
.header__expand .main-menu__website-container-title {
  display: none;
}
.header__expand .main-menu__website-container-dropdown-icon {
  display: none;
}
.header__expand .main-menu__bars {
  display: block;
}

.main-menu__logo {
  background: url(https://s3.eu-west-1.amazonaws.com/beacon.assets/publicapp/assets/Veracity-icon-white-150.png) no-repeat;
  background-position: center;
  background-size: 100%;
}

.main-menu__logo-dark {
  background: url(https://s3.eu-west-1.amazonaws.com/beacon.assets/publicapp/assets/Veracity-icon-white-150.png) no-repeat;
  background-position: center;
  background-size: 100%;
}

.header__small-expand {
  display: flex !important;
  flex-flow: column;
  float: left;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 12;
}

.header--v1 {
  background-color: #5E6172;
  background: #5E6172;
}

.header__content--v1 {
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
}

.header__menu--v1 {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  grid-column-gap: 20px;
  align-items: center;
  justify-send: end;
}

.header__company--v1 {
  margin-right: -4px;
  padding: 5px 18px 5px 12px;
  box-shadow: inset -6px 0 4px rgba(0, 0, 0, 0.175);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: inline-block;
  background-color: #E6EBED;
}

.react-tabs__tab--selected-custom {
  border-radius: 5px;
  background-color: rgba(230, 235, 237, 0.1411764706);
}

.alert-block:hover .alert-close-button {
  visibility: visible;
}

.alert-close-button {
  background-color: transparent;
  border-radius: 5px;
  padding: 2px 5px;
  cursor: pointer;
  visibility: hidden;
}
.alert-close-button:hover {
  background-color: rgb(230, 235, 237);
}

.alert-settings-button {
  background-color: transparent;
  border-radius: 5px;
  padding: 2px 5px;
  cursor: pointer;
}
.alert-settings-button:hover {
  background-color: rgb(230, 235, 237);
}

.alert-settings-button-white {
  background-color: transparent;
  border-radius: 5px;
  padding: 2px 5px;
  cursor: pointer;
}
.alert-settings-button-white:hover {
  background-color: white;
}

.filters__content {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: auto;
}
.filters__content.two {
  grid-template-columns: max-content auto;
}
.filters__content.three {
  grid-template-columns: max-content max-content auto;
}
.filters__content.four {
  grid-template-columns: max-content max-content max-content auto;
}
.filters__content.five {
  grid-template-columns: max-content max-content max-content max-content auto;
}

.tooltip {
  /* wrapper */
}

/* on hover display the tooltip */
.tooltip:hover .tooltip__tip {
  display: block;
}

/* the tool tip text content */
.tooltip__text--has-hint {
  cursor: default;
  border-bottom: 1px dashed #989898;
  margin-bottom: -1px;
}

.tooltip__text--has-pointer {
  cursor: pointer;
}

/* the tooltip tip content */
.tooltip__tip {
  display: none;
  min-width: 150px;
  padding: 0.3rem 0.5rem;
  text-align: center;
  position: absolute;
  z-index: 99;
}
.tooltip__tip a {
  color: #fff;
}

/* styles for top-positioned tooltips */
.tooltip__tip.top {
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

/* styles for top-positioned tooltips */
.tooltip__tip.top-left {
  bottom: 30px;
  left: 0;
}

.tooltip__tip.right {
  top: 0;
  right: -10px;
  transform: translateX(100%);
}

/* styles for bottom-positioned tooltips */
.tooltip__tip.bottom {
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip__tip.bottom-right {
  top: 30px;
  /* left: 50%; */
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translate(-70%, 25%);
}

.tooltip__tip.left {
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

/* the tool tip text content */
.tooltip__text {
  color: inherit;
  display: inline-block;
  width: 100%;
}

/* do something with the text on hover - optional */
/* define basic animation for tooltips */
@keyframes tooltipAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* the tooltip tip content */
.tooltip__tip {
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  border-radius: 4px;
  animation: tooltipAnim 0.15s ease-in;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  text-transform: none;
  font-size: 14px;
}

/* create the arrow for top-positioned tooltips */
.tooltip__tip.top:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.85);
  border-bottom: 0;
  margin-left: -6px;
  margin-bottom: -6px;
}

.tooltip__tip.top-left:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 10%;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.85);
  border-bottom: 0;
  margin-left: -6px;
  margin-bottom: -6px;
}

/* create the arrow for bottom-positioned tooltips */
.tooltip__tip.bottom:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-top: 0;
  margin-left: -6px;
  margin-top: -6px;
}

.tooltip__list {
  text-align: left;
}

.tooltip__list-item {
  display: grid;
  grid-template-columns: 125px max-content;
}

.tooltip__list--help {
  display: flex;
  justify-content: flex-end;
  color: #fff;
}

.tooltip-arrow::after {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #d617e0;
  content: "";
  height: 0;
  margin-left: -10px;
  position: absolute;
  bottom: -4px;
  left: 50%;
  width: 0;
}

.tooltip-arrow-blue::after {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #642f86;
  content: "";
  height: 0;
  margin-left: -10px;
  position: absolute;
  bottom: -4px;
  left: 50%;
  width: 0;
}

.help-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9;
  transition: opacity 200ms linear;
}
.help-overlay-appear {
  opacity: 0;
}
.help-overlay-appear-active {
  opacity: 1;
}
.help-overlay.leave {
  opacity: 0;
}

.help {
  position: fixed;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 350px;
  max-height: 450px;
  overflow-y: auto;
  z-index: 10;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  transition: all 200ms ease-in;
}
.help-appear {
  opacity: 0;
  top: -10%;
}
.help-appear-active {
  opacity: 1;
  top: 50px;
}
.help.leave {
  opacity: 0;
  top: -10%;
}

.help__title {
  color: #5E6172;
  font-size: 16px;
  margin-bottom: 20px;
}

.help__body {
  padding-bottom: 50px;
  word-break: normal;
}
.help__body hr {
  border: 0;
  height: 0;
  border-bottom: 1px solid #E6EBED;
}

.help__close {
  position: fixed;
  top: 10px;
  right: 10px;
}

.logo {
  line-height: 0;
}
.logo img {
  height: 35px;
}

.logo-upload {
  width: 125px;
  height: 125spx;
  position: relative;
}

.logo-upload__image {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}

.logo-upload__button {
  position: absolute;
  top: -11px;
  right: -11px;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: grid;
  text-align: center;
  align-items: center;
  font-size: 10px;
}

.paging {
  display: grid;
  justify-self: end;
  grid-auto-flow: column;
  grid-gap: 10px;
  align-items: center;
}

.loader__icon {
  z-index: 5;
  width: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.form__item {
  position: relative;
  margin-bottom: 30px;
}

.form__label {
  display: block;
  margin-bottom: 5px;
}

.form__input, .shortlink-existing__input, .shortlink-new__input {
  display: block;
  width: 100%;
  border: 1px solid #E6EBED;
  padding: 4px 6px;
  border-radius: 4px;
  transition: all 0.1s linear;
}
.form__input:focus, .shortlink-existing__input:focus, .shortlink-new__input:focus {
  outline: none;
  border: 1px solid #989898;
}
.form__input:disabled, .shortlink-existing__input:disabled, .shortlink-new__input:disabled {
  background-color: #E6EBED;
  color: #989898;
}

.form__textarea {
  min-height: 150px;
}

.form__optional {
  font-weight: 400;
  color: #989898;
  float: right;
}

.form__hint {
  font-weight: 400;
  color: #642f86;
  float: right;
}

.form__password-eye {
  cursor: pointer;
  position: absolute;
  color: #ffffff;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.form__two-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.form__three-column {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.form__four-column {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.form__five-column {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.form__title {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 15px;
  display: block;
  padding-bottom: 5px;
  border-bottom: 1px solid #E6EBED;
}

.form__prepend {
  position: relative;
}
.form__prepend span {
  padding: 4px 10px;
  position: absolute;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #E6EBED;
  border: 1px solid #989898;
}
.form__prepend .form__input, .form__prepend .shortlink-existing__input, .form__prepend .shortlink-new__input {
  padding: 4px 6px 4px 38px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: "Select a file";
  display: inline-block;
  background-color: #5E6172;
  border-radius: 4px;
  padding: 5px 12px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  color: #ffffff;
}

fieldset {
  border: 1px solid #E6EBED;
  padding: 20px;
}

legend {
  font-weight: 700;
}

.tag {
  margin-right: 6px;
  display: inline-block;
  font-size: 12px;
  background-color: #E6EBED;
  padding: 2px 8px;
  border-radius: 5px;
  border: 1px solid #fff;
}

.tag--green {
  background-color: #389363;
  color: #ffffff;
}

.tag__remove {
  padding-left: 6px;
}

.table {
  width: 100%;
  display: table;
  border: 1px solid #E6EBED;
  border-right: 0;
  text-align: left;
}

.table--borderless {
  box-shadow: none;
}

.table__heading,
.table__cell {
  padding: 10px;
}
.table__heading.is-fixed,
.table__cell.is-fixed {
  position: sticky;
  z-index: 1;
  top: 0px;
}
.table__heading.is-fixed-bottom,
.table__cell.is-fixed-bottom {
  position: sticky;
  z-index: 1;
  bottom: 0px;
  background-color: #662d91;
  color: white;
  border-right: none;
}

.table__heading {
  background-color: #fff;
  display: table-cell;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  color: #989898;
  text-align: left;
}
.table__heading:last-child {
  border-right: 1px solid #E6EBED;
}
.table__heading.has-options {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
}

.table__options {
  justify-self: end;
}

.is-sortable {
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}

.table__row {
  background-color: #ffffff;
  transition: all 0.15s linear;
  display: table-row;
}
.table__row:nth-child(odd) {
  background-color: #f5f7f8;
}
.table__row.is-fixed {
  position: sticky;
  z-index: 1;
  top: 0px;
}

.table__row--link {
  cursor: pointer;
  text-decoration: none;
}
.table__row--link:hover {
  background-color: #E6EBED;
}
.table__row--link:hover .table__copy {
  display: inline-block;
}

.table__row--disabled {
  color: #ced8dc;
}

.table__cell {
  display: table-cell;
  border-top: 1px solid #E6EBED;
  border-right: 1px solid #E6EBED;
  vertical-align: middle;
}
.table__cell.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table__separator {
  border-left: 1px dashed #989898;
}

.table__cell--wrap {
  display: none;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
}

.table__cell--right {
  text-align: right;
}

.table__cell--with-tag {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
}

.table__actions {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  justify-content: end;
}

.table__description {
  color: #989898;
  padding-left: 20px;
}

.table__cell--right {
  text-align: right;
}

.table__break-word {
  word-break: break-word;
}

.table-wrap {
  overflow-x: hidden;
  border-right: 3px dotted #E6EBED;
}
.table-wrap:hover {
  overflow: visible;
  position: relative;
  z-index: 2;
  left: -100%;
}
.table-wrap:hover .table--is-popover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}
.table-wrap:hover:after {
  content: "";
  display: block;
  animation: fadeIn 0.25s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: -1;
  pointer-events: none;
}
.table-wrap:hover .table__cell--wrap {
  display: inline-block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.table--is-popover {
  transition: all 0.25s;
  table-layout: fixed;
}
.radio-container, .radio-container__inline {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-container__inline {
  display: inline-flex;
  margin-bottom: 0;
}

/* Hide the browser's default radio button */
.radio-container input, .radio-container__inline input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 21px;
  width: 22px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark,
.radio-container__inline:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark,
.radio-container__inline input:checked ~ .checkmark {
  background-color: #25CCCF;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after,
.radio-container__inline input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after,
.radio-container__inline .checkmark:after {
  top: 7px;
  left: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.sidebar__item {
  padding-left: 10px;
}

.sidebar__link {
  position: relative;
  color: #989898;
  text-decoration: none;
  padding: 10px 0 10px 27.5px;
  display: block;
  transition: all 0.1s linear;
}
.sidebar__link:hover {
  color: #5E6172;
}

.sidebar__link--active {
  color: #5E6172;
}

.sidebar__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.main-menu {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #f8f9fa;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.08);
}

.main-menu__menu {
  display: grid;
  align-items: center;
  grid-gap: 30px;
  grid-template-columns: max-content max-content max-content max-content max-content max-content auto max-content;
}

.main-menu__toolbar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  color: white;
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
}

.main-menu__toolbar-item {
  margin: auto;
  cursor: pointer;
  margin-bottom: 5px;
}

.main-menu__item {
  text-decoration: none;
}
.main-menu__item.split {
  border-left: 1px solid #989898;
  padding-left: 30px;
}

.side-bar__item {
  color: white;
  text-align: left;
  padding: 5%;
  padding-left: 10%;
  margin-bottom: 10px;
  cursor: pointer;
}

.main-menu__nav-link {
  text-decoration: none;
}

.main-menu__link {
  display: block;
  text-decoration: none;
  color: #989898;
  transition: all 0.1s linear;
}

.main-menu__link--active {
  color: #5E6172;
}

.main-menu__link--selected {
  background-color: #6E7080;
  text-decoration: none;
  border-left-width: 4px;
  border-left-color: #25CCCF;
  border-left-style: solid;
}

.main-menu__shortlink {
  justify-self: end;
  margin-right: 0;
}

.main-menu__inline {
  color: white;
  display: inline;
  font-size: 15px;
}

.main-menu__icon {
  color: white;
  margin-right: 10px;
  font-size: 15px;
}

.main-menu__website-container {
  display: grid;
  grid-template-columns: max-content auto max-content;
}

.main-menu__website-container-title {
  margin: auto;
  margin-left: 0;
  display: table;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

@keyframes textloop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
  0% {
    transform: translateX(100%);
  }
}
.main-menu__website-container-dropdown-icon {
  background-image: url(https://s3.eu-west-1.amazonaws.com/beacon.assets/publicapp/assets/arrow-down-light.svg);
  color: white;
  background-size: 11px;
  background-repeat: no-repeat;
  background-position: center center;
  width: 51px;
}

.main-menu__bars {
  display: none;
  width: 100%;
  text-align: center;
}

.header__small--overlay {
  display: none;
}

@media screen and (max-width: 1100px) {
  .dashboard-shortlink {
    display: none;
  }

  .dashboard-multilink {
    display: none;
  }

  .dashboard-help {
    display: none;
  }

  .header__small-header {
    display: grid;
    grid-template-columns: max-content auto max-content;
  }

  .header {
    display: none;
  }

  .header__expand {
    display: none;
  }

  .main-content {
    width: 100%;
    clear: both;
    padding-left: 0;
    padding-right: 0;
  }

  .main-content--collapsed {
    width: 100%;
    clear: both;
    padding-left: 0;
    padding-right: 0;
  }

  .header__small--overlay {
    display: block;
  }
}
.main-menu--v1 {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #f8f9fa;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.08);
}

.main-menu__menu--v1 {
  display: grid;
  align-items: center;
  grid-gap: 30px;
  grid-template-columns: max-content max-content max-content max-content max-content max-content auto max-content;
}

.main-menu__item--v1.split {
  border-left: 1px solid #989898;
  padding-left: 30px;
}

.main-menu__link--v1 {
  display: block;
  text-decoration: none;
  color: #989898;
  transition: all 0.1s linear;
}
.main-menu__link--v1:hover {
  color: #5E6172;
}

.main-menu__link--active--v1 {
  color: #5E6172;
}

.main-menu__shortlink--v1 {
  justify-self: end;
  margin-right: 0;
}

.overlay {
  max-width: 80rem;
  margin: auto;
}
@media screen and (max-width: 80rem) {
  .overlay {
    padding: 0 20px;
  }
}

@keyframes maskEnter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes maskLeave {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sideBarEnter {
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
}
@keyframes sideBarLeave {
  0% {
    right: 0;
  }
  100% {
    right: -100%;
  }
}
.overlay__mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
  animation: maskEnter 200ms both;
}
.overlay__mask.leave {
  animation: maskLeave 200ms both;
}

.overlay__content {
  position: fixed;
  top: 0;
  right: 0;
  width: 1280px;
  max-width: 100%;
  height: 100%;
  background-color: #f8f9fa;
  z-index: 13;
  animation: sideBarEnter 200ms both;
}
.overlay__content.leave {
  animation: sideBarLeave 200ms both;
}

.scroll__view {
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.overlay__header {
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  position: sticky;
  top: 63px;
  z-index: 5;
  background-color: #f8f9fa;
}

.overlay__title {
  color: #989898;
  margin-bottom: 10px;
}

.overlay__tools {
  justify-self: end;
}
.overlay__tools li {
  display: inline-block;
  margin-left: 10px;
}

.overlay__subnav {
  padding: 10px 10px;
  background-color: #f8f9fa;
  position: sticky;
  top: 90px;
  z-index: 5;
  display: grid;
  grid-template-columns: auto max-content;
  grid-column-gap: 20px;
  align-items: top;
  background-repeat: repeat;
  background-size: 75px;
  border: rgba(128, 128, 128, 0.231372549) solid 1px;
  border-radius: 5px;
}

.overlay__element-title {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 15px;
}

.overlay__details {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  align-items: center;
}

.overlay__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@keyframes composerEnter {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes composerLeave {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
}
.composer {
  position: fixed;
  z-index: 8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  width: 100%;
  max-width: 1280px;
  height: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content auto max-content;
  grid-template-areas: "title title" "composer preview" "footer footer";
  animation: composerEnter 200ms both;
}
.composer.leave {
  animation: composerLeave 200ms both;
}

.composer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 7;
  animation: overlayEnter 200ms both;
}
.composer-overlay.leave {
  animation: overlayLeave 200ms both;
}

.composer__options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  margin-bottom: 20px;
}

.composer__title {
  background-color: #ffffff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  grid-area: title;
  padding: 15px 20px;
  font-size: 16px;
  border-bottom: 1px solid #E6EBED;
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
}

.composer__close {
  justify-self: end;
}

.composer__create {
  background-color: #ffffff;
  padding: 40px;
  overflow-y: auto;
  grid-area: composer;
}

.composer__preview {
  background-color: #f8f9fa;
  padding: 40px;
  grid-area: preview;
  overflow-y: auto;
}

.composer__action {
  border-top: 1px solid #E6EBED;
  grid-area: footer;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  background-color: #ffffff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 15px 20px;
  justify-content: end;
}

.composer__additional {
  display: grid;
  grid-template-columns: auto max-content max-content max-content;
  grid-column-gap: 10px;
  position: relative;
  align-items: center;
}

.composer__emoji-picker {
  position: absolute;
  right: 0;
  top: 32px;
}

.composer__chars.danger {
  color: #f04c4c;
}

.composer__date {
  position: relative;
}
.composer__date .button, .composer__date .report-list__create, .composer__date .report-builder__generate, .composer__date .report-campaign__remove, .composer__date .font-awesome-picker__button, .composer__date .social-account-connect li, .social-account-connect .composer__date li, .composer__date .publish-link__post, .composer__date .publish-link__short-url, .composer__date input {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.composer__date input {
  text-align: left;
  border: 1px solid #E6EBED;
  width: 225px;
  border-radius: 4px;
  color: #5E6172;
}

.composer__remove-media {
  position: absolute;
  bottom: 70px;
  right: 30px;
}

.traffic-breakdown {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  justify-content: center;
}

.traffic-breakdown__legend {
  padding-right: 30px;
}

.social-stats {
  font-size: 12px;
  justify-self: end;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
}

.social-stats__icon {
  margin-right: 7px;
}

.web-stats {
  font-size: 12px;
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-column-gap: 20px;
}

.web-stats__icon {
  margin-right: 7px;
}

.publish-link__short-url {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid #cca800;
}

.publish-link__post {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.social-account-inline {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  align-items: center;
}

.social-account-inline__avatar img {
  width: 18px;
  height: 18px;
  border-radius: 10px;
  border: 1px solid #ffffff;
  display: block;
}

.social-account-inline__network {
  display: grid;
  height: 18px;
  width: 18px;
  border-radius: 10px;
  margin-left: -7px;
  border: 1px solid #ffffff;
  margin-right: 5px;
}
.social-account-inline__network.twitter {
  background-color: #28AAE1;
}
.social-account-inline__network.facebook {
  background-color: #3B5999;
}
.social-account-inline__network.linkedin {
  background-color: #0077B5;
}

.social-account-inline__icon {
  align-self: center;
  justify-self: center;
  color: #ffffff;
  font-size: 9px;
}

.social-account-inline__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 135px;
}

.social-account-inline__notice {
  font-size: 12px;
  color: #f04c4c;
  padding-right: 8px;
}

@keyframes applause {
  0% {
    top: 20px;
    opacity: 0;
  }
  100% {
    top: 10px;
    opacity: 1;
  }
}
.applause {
  animation: applause 0.25s both;
  animation-delay: 2.5s;
  position: absolute;
  background-color: #5E6172;
  color: #fff;
  border-radius: 4px;
  width: calc(100% - 20px);
  top: 10px;
  left: 10px;
  z-index: 1;
  padding: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.notifications {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 300px;
  z-index: 25;
}

@keyframes notificationsEnter {
  0% {
    opacity: 0;
    bottom: -50px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}
@keyframes notificationsLeave {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.notifications__item {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 15px;
  position: relative;
  animation: notificationsEnter 250ms, notificationsLeave 250ms 5750ms;
}
.notifications__item.light {
  background-color: #ffffff;
  color: #5E6172;
}
.notifications__item.dark {
  background-color: #5E6172;
  color: #ffffff;
}
.notifications__item.positive {
  background-color: #642f86;
  color: #ffffff;
}
.notifications__item.negative {
  background-color: #f04c4c;
  color: #ffffff;
}

.message {
  padding: 10px 12px;
  border-radius: 4px;
  overflow: auto;
}

.message__title {
  font-weight: 700;
  margin-bottom: 10px;
}

.message--warning {
  border: 1px solid #f04c4c;
  background-color: rgba(240, 76, 76, 0.075);
}

.message--notice {
  border: 1px solid #FFD200;
  background-color: rgba(255, 210, 0, 0.075);
}

.shortlink {
  display: grid;
  grid-template-columns: 75fr 25fr max-content;
  align-items: center;
}

.shortlink__button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.shortlink__input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #5E6172;
}
.shortlink__input:focus {
  border: 1px solid #5E6172;
}

.shortlink-new__title {
  font-size: 20px;
  line-height: 28px;
  text-align: center;
}

.shortlink-new__original {
  text-align: center;
  word-wrap: break-word;
}

.shortlink-new__short {
  position: relative;
}

.shortlink-new__input {
  font-size: 22px;
}

.shortlink-new__copy {
  position: absolute;
  background-color: #E6EBED;
  height: calc(100% - 2px);
  border: 1px solid #ffffff;
  margin: 1px;
  top: 0;
  right: 0;
}

.shortlink-existing__input {
  font-size: 22px;
}

@keyframes modalEnter {
  0% {
    opacity: 0;
    top: -100%;
  }
  100% {
    opacity: 1;
    top: 50%;
  }
}
@keyframes modalLeave {
  0% {
    opacity: 1;
    top: 50%;
  }
  100% {
    opacity: 0;
    top: -100%;
  }
}
.modal-mask {
  animation: maskEnter 200ms both;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 15;
  overflow-y: auto;
  padding-right: 17px;
  /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box;
  /* So the width will be 100% + 17px */
}
.modal-mask.leave {
  animation: maskLeave 200ms both;
}

.modal {
  animation: modalEnter 200ms;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  z-index: 16;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  text-align: left;
}
.modal.leave {
  animation: modalLeave 200ms both;
}

.modal--wide {
  width: 85vw;
}

.modal__title {
  background-color: #ffffff;
  padding: 10px 20px;
  border-bottom: 1px solid #E6EBED;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #989898;
}

.modal__content {
  background-color: #ffffff;
  padding: 20px;
  max-height: 75vh;
  overflow-y: auto;
}

.modal__footer {
  display: grid;
  justify-content: end;
  background-color: #ffffff;
  padding: 20px;
  background-color: #E6EBED;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  grid-auto-flow: column;
  grid-column-gap: 10px;
}

.og-graph {
  margin-top: 15px;
  overflow: hidden;
  border: 1px solid #E6EBED;
  border-radius: 6px;
}

.og-graph__title {
  font-weight: 700;
  margin-bottom: 8px;
}

.og-graph__content {
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.og-graph__image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.og-graph--not-available {
  height: 225px;
  font-size: 17px;
  color: #989898;
  background-color: #E6EBED;
  display: grid;
  align-content: center;
  justify-content: center;
}

.og-graph--summary,
.og-graph--undefined {
  display: grid;
  grid-template-columns: 33fr 66fr;
}
.og-graph--summary .og-graph__image,
.og-graph--undefined .og-graph__image {
  height: 120px;
}
.og-graph--summary .og-graph__content,
.og-graph--undefined .og-graph__content {
  max-height: 105px;
}

.og-graph--summary_large_image .og-graph__image {
  padding-top: 50%;
}

.post-media img {
  max-width: 100%;
  display: block;
}

.post-media__item {
  margin-top: 15px;
  overflow: hidden;
  border: 1px solid #E6EBED;
  border-radius: 6px;
}

.post-media__thumbnail {
  position: relative;
  margin-left: 15px;
  background-size: cover;
  background-position: center center;
  border: 1px solid #E6EBED;
  border-radius: 6px;
  height: 50px;
  width: 50px;
  float: right;
}
.post-media__thumbnail:hover img {
  display: block;
}
.post-media__thumbnail img {
  display: none;
  max-width: 400px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.social-account-connect {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 10px;
}
.social-account-connect li {
  color: #ffffff;
}
.social-account-connect li.twitter {
  background-color: #28AAE1;
}
.social-account-connect li.facebook {
  background-color: #3B5999;
}
.social-account-connect li.linkedin {
  background-color: #0077B5;
}

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .prevMonthDay,
.flatpickr-calendar.multiMonth .nextMonthDay {
  visibility: hidden;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 28px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0px;
  line-height: 16px;
  height: 28px;
  padding: 10px;
  z-index: 3;
}

.flatpickr-months .flatpickr-prev-month.disabled,
.flatpickr-months .flatpickr-next-month.disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  left: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  right: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 6.16px 0 0 0;
  line-height: 1;
  height: 28px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0 ;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange,
.flatpickr-day.startRange.startRange + .endRange,
.flatpickr-day.endRange.startRange + .endRange {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  display: inline-block;
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  cursor: pointer;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #f0f0f0;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes dateRangeSelectEnter {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes dateRangeSelectLeave {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
}
.date-range-select-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 7;
  animation: overlayEnter 200ms both;
}
.date-range-select-mask.leave {
  animation: overlayLeave 200ms both;
}

.date-range-select {
  position: fixed;
  z-index: 8;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  animation: dateRangeSelectEnter 200ms both;
}
.date-range-select.leave {
  animation: dateRangeSelectLeave 200ms both;
}
.date-range-select .flatpickr-input {
  display: none;
}

.date-range-select__picker {
  margin-bottom: 30px;
}

.date-range-select__tools {
  display: grid;
  grid-template-columns: auto max-content max-content;
  grid-column-gap: 10px;
  align-items: center;
}

.date-range-select__dates {
  color: #ffffff;
  justify-self: start;
}

.hide-datepicker-input .flatpickr-input {
  display: none;
}

.accounts-tools {
  display: grid;
  justify-content: end;
  grid-gap: 20px;
  margin-bottom: 20px;
  align-items: center;
}

.edit-mode {
  background-image: linear-gradient(white 2px, transparent 2px), linear-gradient(90deg, white 2px, transparent 2px), linear-gradient(rgba(255, 255, 255, 0.3) 1px, transparent 1px), linear-gradient(90deg, rgba(255, 255, 255, 0.3) 1px, transparent 1px);
  background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
  margin-left: 10px;
  margin-right: 10px;
}

.item-placeholder-class {
  background-image: linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 1s infinite linear;
}

@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
  }
}
.react-grid-item.react-grid-placeholder {
  background-image: linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 1s infinite linear;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.no-data {
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 20px;
  text-align: center;
  color: #989898;
}

.no-data__icon {
  display: block;
  font-size: 26px;
  margin-bottom: 10px;
}

.no-data__action {
  margin-top: 20px;
}
.no-data__action a {
  color: #989898;
}

.contributing-posts__title {
  margin-bottom: 20px;
  color: #989898;
}

.contributing-posts__clear {
  margin-left: 10px;
}

.definition-list__item {
  padding: 10px 0;
}
.definition-list__item:last-child {
  border: 0;
}

.definition-list__title {
  color: #989898;
}

.definition-list__title--inline {
  display: inline-block;
}

.definition-list__value--inline {
  display: inline-block;
}

.social-window {
  padding: 40px;
}
.social-window h1 {
  font-size: 24px;
  margin-bottom: 20px;
}
.social-window p {
  margin-bottom: 20px;
}
.social-window label {
  display: block;
  font-size: 18px;
  margin-bottom: 10px;
}
.social-window select {
  display: block;
  margin-bottom: 20px;
}

@keyframes firstLoginEnter {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes firstLoginLeave {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
}
.first-login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: firstLoginEnter 200ms both;
}
.first-login.leave {
  animation: firstLoginLeave 200ms both;
}

.first-login__slide {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.first-login__content {
  width: 450px;
  height: 400px;
  padding: 30px;
  background-color: #ffffff;
  position: relative;
}

.first-login__image {
  width: 450px;
  height: 400px;
  background-size: cover;
  background-position: center center;
  background: linear-gradient(to right, rgb(107, 50, 141), rgb(214, 23, 224));
}

.first-login__title {
  font-size: 23px;
  margin-bottom: 20px;
}

.first-login__subtitle {
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 20px;
}

.first-login__actions {
  position: absolute;
  bottom: 60px;
  right: 35px;
}

.new-user-welcome {
  padding: 60px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
}

.new-user-welcome__close {
  position: absolute;
  top: 15px;
  right: 15px;
}

.new-user-welcome__title {
  font-size: 22px;
}

.not-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.not-found h1 {
  font-size: 28px;
  margin-bottom: 15px;
}

.takeover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(94, 97, 114, 0.85);
  z-index: 24;
}

.takeover__content {
  width: 500px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.takeover__title {
  font-size: 28px;
  line-height: 38px;
}

.takeover__footer {
  border-radius: 4px;
  background-color: #989898;
  padding: 10px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
}

.takeover__logout {
  cursor: pointer;
  justify-self: end;
  color: #ffffff;
}

.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  padding: 5px 12px;
  border-radius: 4px;
  color: #ffffff;
  padding-right: 35px;
  width: 100%;
}
.select:focus {
  outline: 0;
}
.select option {
  color: #5E6172;
}
.select:disabled {
  cursor: default;
  opacity: 0.7;
}

.select-container {
  cursor: pointer;
  position: relative;
  display: inline-block;
  border-radius: 4px;
  background-size: 11px;
  background-repeat: no-repeat;
  background-position: center right 12px;
  background-color: #5E6172;
}
.select-container:after {
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  content: "";
  display: block;
  background-size: 11px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #5E6172;
  background-image: url("https://s3.eu-west-1.amazonaws.com/beacon.assets/publicapp/assets/arrow-down-light.svg");
  width: 15px;
  height: 15px;
}

.add-team-member {
  background-color: #E6EBED;
  padding: 20px;
  border-radius: 4px;
}

.add-team-member__title {
  font-size: 18px;
  margin-bottom: 15px;
}

.nag {
  position: fixed;
  z-index: 6;
  bottom: 50px;
  right: 50px;
  max-width: 350px;
  background-color: #FFD200;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.nag__icon {
  margin-right: 5px;
}

.nag__title {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
}

.nag__body {
  margin-bottom: 15px;
}

.nag__actions {
  display: grid;
  align-items: center;
  grid-template-columns: auto max-content;
}
.nag__actions button {
  color: #5E6172;
}

.click-fraud {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: top;
  grid-gap: 40px;
}

.click-fraud__bar {
  width: 100%;
  white-space: nowrap;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: max-content auto max-content;
  align-items: center;
  text-align: center;
}
.click-fraud__bar .fa-robot {
  color: #5E6172;
}
.click-fraud__bar .fa-robot.isLight {
  color: #ffffff;
}
.click-fraud__bar .fa-user {
  color: #5E6172;
}
.click-fraud__bar .fa-user.isLight {
  color: #ffffff;
}
.click-fraud__bar .bots {
  display: inline-block;
  background-color: #f04c4c;
  height: 20px;
}
.click-fraud__bar .humans {
  display: inline-block;
  background-color: #389363;
  height: 20px;
  margin-left: 1px;
}

.click-fraud__icon {
  border: 1px solid #5E6172;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: grid;
  justify-content: center;
  align-content: center;
}
.click-fraud__icon.isLight {
  border: 1px solid #ffffff;
}

.click-fraud__item.bots {
  color: #f04c4c;
}
.click-fraud__item.humans {
  color: #389363;
}

/**
    Channels
*/
.channels input:checked + .channels__item {
  background-color: #FFD200;
  border: 1px solid #FFD200;
}
.channels__item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 4px;
  align-items: center;
  padding: 4px;
  border: 1px solid #E6EBED;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}
.channels__item:hover {
  background-color: #E6EBED;
}

.channels__title {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 6px;
}

.channels__icon {
  border-radius: 4px;
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  position: relative;
}
.channels__icon i {
  text-transform: uppercase;
  font-size: 18px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.channels__icon--inline {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  background-color: #E6EBED;
}

.channels__spend {
  position: relative;
  z-index: 1;
  justify-self: end;
}

/**
    Star Rating
*/
.star-rating__star {
  color: #E28413;
}

.star-rating__star--white {
  color: #ffffff;
}

.journey__icon {
  font-size: 32px;
}

.journey__item {
  text-decoration: none;
  display: grid;
  align-items: center;
  grid-template-columns: max-content auto max-content;
  grid-gap: 15px;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #E6EBED;
  border-radius: 4px;
}
.journey__item:last-child {
  margin-bottom: 0;
}

.journey__actions {
  justify-self: end;
}

.journey__exit {
  color: #f04c4c;
  padding-left: 15px;
  font-size: 12px;
}

.journey__screenshot {
  width: 100px;
}

.journey__screenshot--none {
  width: 100px;
  height: 56px;
  background-color: #E6EBED;
  display: grid;
  align-content: center;
  justify-content: center;
  color: #989898;
  font-size: 11px;
}

.spend {
  padding: 20px;
  background-color: #2E294E;
  color: #ffffff;
  display: grid;
  grid-template-columns: 6fr 4fr;
  grid-gap: 10px;
  align-content: center;
}

.spend__cpv {
  font-size: 40px;
  line-height: 1.25;
}

.spend__bar {
  height: 15px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.15);
}

.spend__percentage {
  background: #ffffff;
  height: 15px;
}

.visitor-actions {
  display: grid;
  grid-template-columns: repeat(5, 30px);
  grid-gap: 5px;
}

.visitor-actions__icon {
  border: 1px solid #989898;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: grid;
  justify-content: center;
  align-content: center;
}

.visitor-actions__icon--green {
  color: #fff;
  border: none;
  background: linear-gradient(332deg, rgb(54, 176, 177) 15%, rgb(37, 204, 207) 67%, rgb(37, 204, 207) 83%);
}

.visitor-actions__icon--red {
  color: #fff;
  border: none;
  background: rgba(240, 76, 76, 0.7490196078);
}

.visitor-actions__icon--gold {
  color: #fff;
  border: none;
  background-image: linear-gradient(#FFD200, #E28413);
}

.visitor-card {
  display: block;
  position: relative;
  border: 1px solid #E6EBED;
  padding: 15px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  transition: all 0.1s;
}
.visitor-card:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.visitor-card__icon {
  font-size: 21px;
  margin: 0 auto 8px auto;
  border: 1px solid #989898;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: grid;
  justify-content: center;
  align-content: center;
}

.visitor-card__detail {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.visitor-card__detail h4 {
  margin-top: 10px;
  color: #989898;
}

/**
    Top-level stats
*/
.top-level-stats {
  display: grid;
  grid-template-columns: 4fr 4fr 2fr;
  grid-gap: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #E6EBED;
  border-bottom: 1px solid #E6EBED;
}

.top-level-stats__item {
  display: grid;
  grid-gap: 20px;
  align-items: center;
  padding: 10px 20px;
  border-right: 1px solid #E6EBED;
}
.top-level-stats__item:last-child {
  border: 0;
}

.top-level-stats__item--full {
  grid-template-columns: 1fr;
  text-align: center;
}

.top-level-stats__header {
  text-align: center;
}
.top-level-stats__header:hover .primary {
  display: none;
}
.top-level-stats__header:hover .secondary {
  display: block;
}
.top-level-stats__header .primary {
  margin: auto;
  width: 75px;
}
.top-level-stats__header .secondary {
  margin: auto;
  width: 75px;
  display: none;
}

.top-level-stats__headline {
  display: block;
  font-size: 26px;
  line-height: 34px;
}

/**
    Key dot
*/
.key-dot {
  display: inline-block;
  margin-right: 5px;
  width: 11px;
  height: 11px;
  border-radius: 6px;
  border: 1px solid #fff;
}
.key-dot.spacer {
  background-color: transparent;
}
.key-dot.pink {
  background-color: #FF3190;
}
.key-dot.blue {
  background-color: #d617e0;
}
.key-dot.yellow {
  background-color: #FFD200;
}
.key-dot.red {
  background-color: #f04c4c;
}
.key-dot.green {
  background-color: #389363;
}
.key-dot.purple {
  background-color: #2E294E;
}

.key-dot--above {
  margin: 0 0 10px 0;
  width: 17px;
  height: 7px;
  display: block;
}

.key-dot--over-icon {
  position: absolute;
  top: -3px;
  left: -3px;
}

/**
    Tabs
*/
.react-tabs {
  -webkit-tap-highlight-color: transparent;
}
.react-tabs__tab-list {
  margin-bottom: 30px;
  z-index: 1;
}
.react-tabs__tab {
  display: inline-block;
  border-radius: 20px;
  bottom: -2px;
  position: relative;
  list-style: none;
  padding: 6px 16px;
  cursor: pointer;
  color: #989898;
}
.react-tabs__tab i {
  padding-right: 6px;
}
.react-tabs__tab--selected {
  background-color: #E6EBED;
  color: #5E6172 !important;
}
.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}
.react-tabs__tab:hover {
  color: #5E6172;
}
.react-tabs__tab-panel {
  display: none;
}
.react-tabs__tab-panel--selected {
  display: block;
}

/**
    font-awesome-picker
*/
.font-awesome-picker {
  position: relative;
}

.font-awesome-picker__list {
  position: fixed;
  height: 250px;
  border: 1px solid red;
  display: grid;
  grid-template-columns: repeat(5, max-content);
  grid-gap: 10px;
  text-align: center;
  padding: 10px;
  overflow-y: scroll;
  background-color: #ffffff;
  border: 1px solid #E6EBED;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.font-awesome-picker__icon {
  font-size: 22px;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #E6EBED;
  border-radius: 3px;
}
.font-awesome-picker__icon:hover {
  background-color: #E6EBED;
}

/**
    Star Bar
*/
.star-bar {
  display: table;
  width: 94%;
  height: 100%;
  margin-top: 10px;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px;
}

.star-bar__item {
  display: table-row;
  grid-gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.star-bar__bar {
  position: relative;
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  background-color: #E6EBED;
  border-radius: 10px;
  margin-top: 5px;
}

.star-bar__fill {
  background-color: #25CCCf;
  padding: 9px 0;
  border-radius: 10px;
}

.star-bar__percent {
  position: absolute;
  right: 4px;
  font-size: 11px;
  color: "#000";
  top: 50%;
  transform: translateY(-50%);
}

.star-bar__item--v1 {
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: 10px;
  align-items: center;
  margin-bottom: 4px;
}

.star-bar__bar--v1 {
  position: relative;
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  background-color: #E6EBED;
}

.star-bar__fill--v1 {
  background-color: #25CCCf;
  padding: 9px 0;
}

.star-bar__percent--v1 {
  position: absolute;
  right: 4px;
  font-size: 11px;
  color: #989898;
  top: 50%;
  transform: translateY(-50%);
}

/**
    Website image
*/
.website-image {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.website-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.website-image--small {
  height: 31px;
  width: 31px;
}

/**
    Toggle
*/
.toggle {
  display: inline-grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 6px;
  align-items: center;
  cursor: pointer;
  text-align: left;
}
.toggle input {
  display: none;
}
.toggle.disabled {
  opacity: 0.5;
}
.toggle .toggle__switch {
  border: 2px solid #989898;
  display: block;
  width: 40px;
  height: 20px;
  border-radius: 11px;
  position: relative;
}
.toggle .toggle__switch:after {
  display: block;
  content: "";
  width: 12px;
  height: 12px;
  margin: 2px;
  background-color: #989898;
  border-radius: 100%;
}
.toggle input:checked ~ .toggle__switch {
  border: 2px solid #642f86;
}
.toggle input:checked ~ .toggle__switch:after {
  display: block;
  content: "";
  width: 12px;
  height: 12px;
  margin: 2px;
  background-color: #642f86;
  border-radius: 100%;
  float: right;
}
/**
    Switch
*/
.switch {
  display: inline-grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 6px;
  align-items: center;
  cursor: pointer;
  text-align: left;
}
.switch input {
  display: none;
}
.switch.disabled {
  opacity: 0.5;
}
.switch .switch__switch {
  border: 2px solid #642f86;
  display: block;
  width: 40px;
  height: 22px;
  border-radius: 11px;
  position: relative;
}
.switch .switch__switch:after {
  display: block;
  content: "";
  width: 14px;
  height: 14px;
  margin: 2px;
  background-color: #642f86;
  border-radius: 100%;
}
.switch input:checked ~ .switch__switch:after {
  display: block;
  content: "";
  width: 14px;
  height: 14px;
  margin: 2px;
  background-color: #642f86;
  border-radius: 100%;
  float: right;
}

/**
    Export
*/
.export-campaigns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.export-channels {
  padding: 15px;
  border: 1px solid #E6EBED;
}

.export-channels__title {
  margin-bottom: 15px;
  font-weight: 700;
}

/**
    Fixed tools
*/
.fixed-tools {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-top: 1px solid #E6EBED;
  text-align: right;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.05);
}

.layout-account {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 40fr 60fr;
}

.dashboard {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}
.dashboard .card, .dashboard .report-wrap__option, .dashboard .export-campaigns__item {
  min-height: 200px;
}

.dashboard-links {
  margin: -20px;
}

.dashboard-links__item {
  padding: 10px 20px;
  display: block;
  text-decoration: none;
  border-bottom: 1px solid #E6EBED;
}
.dashboard-links__item:hover {
  background-color: #E6EBED;
}

.overlay-analytics__title {
  color: #ffffff;
}

.overlay-analytics__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  grid-template-areas: "overview fraud fraud" "overview retention bounce" "links links links";
}

.overlay-analytics__overview {
  grid-area: overview;
}

.overlay-analytics__fraud {
  grid-area: fraud;
}

.overlay-analytics__retention {
  grid-area: retention;
}

.overlay-analytics__bounce {
  grid-area: bounce;
}

.overlay-analytics__links {
  grid-area: links;
}

/**
    Campaign Block
*/
.campaign-block__header {
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  grid-gap: 15px;
  margin-bottom: 15px;
}

.campaign-block__body {
  display: grid;
  grid-template-columns: 4fr 6fr;
  grid-gap: 15px;
}

.campaign-block__data {
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 15px;
}

.campaign-block__pie {
  text-align: center;
  position: relative;
}

.campaign-block__campaigns {
  text-align: left;
}

.campaign-block__description {
  border-right: 1px solid #E6EBED;
  padding: 0 15px 15px 0;
}

.campaign-block__fraud {
  padding: 0 15px 15px;
}

.campaign-block__pie-visits {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.campaign-block__pie-visits span {
  font-size: 18px;
  display: block;
}

/**
    Section title
*/
.section-title {
  color: #5E6172;
  font-size: 20px;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.65);
}

/**
    Custom tooltip
*/
.custom-tooltip {
  padding: 5px 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  text-align: left;
}

.custom-tooltip__title {
  font-weight: bold;
}

.custom-tooltip__row {
  display: grid;
  grid-gap: 10px;
}
.custom-tooltip__row.two {
  grid-template-columns: repeat(2, 1fr);
}

/**
    Custom legend
*/
.custom-legend {
  text-align: left;
  font-size: 12px;
}

.custom-legend__row {
  display: inline-block;
  margin-right: 10px;
}

/**
List default
*/
.list-default {
  list-style: disc;
  padding-left: 30px;
}

/**
    Switcher
*/
.switcher__select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #5E6172;
  cursor: pointer;
  padding: 4px 10px 4px 10px;
  padding-left: 0;
  padding-right: 35px;
}
.switcher__select:focus {
  outline: 0;
}
.switcher__select option {
  color: #5E6172;
}

.switcher {
  cursor: pointer;
  position: relative;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid rgba(94, 97, 114, 0.1882352941);
  padding-left: 5px;
}
.switcher:after {
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  content: "";
  display: block;
  background-size: 11px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("https://s3.eu-west-1.amazonaws.com/beacon.assets/publicapp/assets/arrow-down-dark.svg");
  width: 15px;
  height: 15px;
}

/**
    Report
*/
.report {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1200;
  background-color: #f8f9fa;
}

.report-wrap {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.report-wrap__close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.report-wrap__title {
  max-width: 475px;
  margin-left: auto;
  margin-right: auto;
  font-size: 34px;
  line-height: 44px;
}

.report-wrap__options {
  display: grid;
  grid-template-columns: repeat(3, 230px);
  grid-gap: 30px;
}

.report-wrap__option {
  cursor: pointer;
  padding: 20px;
}

.report-wrap__icon {
  height: 60px;
}

.report-wrap__type {
  font-size: 18px;
}

.report-wrap__footer {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.report-builder {
  display: grid;
  grid-template-columns: 7fr 3fr;
}

.report-builder__content {
  display: grid;
  grid-template-rows: max-content max-content 1fr max-content;
  height: 100vh;
}

.report-builder__header {
  padding: 15px;
  background-color: #fff;
  border-bottom: 1px solid #E6EBED;
}
.report-builder__header h1 {
  text-align: center;
}

.report-builder__add {
  padding: 20px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 10px;
  align-items: center;
  border-bottom: 1px solid #E6EBED;
}

.report-builder__preview {
  overflow-y: auto;
  padding: 20px;
  position: relative;
  background-color: #fff;
}

.report-builder__preview-content h2 {
  margin-bottom: 20px;
}

.report-builder__preview-content--fetching {
  opacity: 0.6;
  cursor: default;
  user-select: none;
  pointer-events: none;
}

.report-builder__preview-no-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #989898;
  text-align: center;
}

.report-builder__summary {
  border-top: 1px solid #E6EBED;
  border-bottom: 1px solid #E6EBED;
  padding: 20px;
  align-self: end;
  position: relative;
}

.report-builder__footer {
  align-self: end;
}

.report-builder__sidebar {
  background-color: #fff;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
  padding: 10px 10px 70px 10px;
  position: relative;
  height: 100vh;
}

.report-builder__sidebar-scroll {
  overflow-y: auto;
}

.report-campaign {
  display: grid;
  grid-template-columns: auto max-content;
  background-color: #E6EBED;
  padding: 5px;
  margin-bottom: 1px;
  align-items: center;
}

.report-navigation {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}
.report-navigation li {
  cursor: pointer;
  padding: 20px;
  text-align: center;
  color: #989898;
}
.report-navigation li:hover {
  color: #5E6172;
}

.report-navigation__active {
  color: #5E6172 !important;
  background: #FFD200;
}

.report-data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.report-data__item {
  border: 1px solid #E6EBED;
  padding: 10px;
  background-color: #fff;
}

.report-data__label {
  border-bottom: 1px solid #E6EBED;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.report-comparison-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.report-comparison {
  width: 100%;
  margin: auto;
  text-align: center;
}

.report-comparison__blocks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #E6EBED;
}

.report-comparison__title {
  margin-bottom: 10px;
}

.report-comparison__block {
  padding: 25px 0;
  border: 2px solid #fff;
  position: relative;
}
.report-comparison__block:last-child {
  background-color: #ffffe1;
}
.report-comparison__block:after {
  content: "Vs";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -17px;
  border: 2px solid #E6EBED;
  background-color: #fff;
  z-index: 1;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: grid;
  align-content: center;
  justify-content: center;
}
.report-comparison__block:last-child:after {
  display: none;
}

.report-comparison__value {
  font-size: 22px;
  margin-bottom: 10px;
}

.report-comparion__date {
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 30px;
  color: #989898;
}
.report-comparion__date span {
  color: #5E6172;
  border-bottom: 1px dashed #989898;
}

.report-comparison__arrow {
  color: #989898;
  font-size: 14px;
  margin-left: 6px;
  vertical-align: top;
}

.report-type-selector {
  display: grid;
  grid-gap: 10px;
  align-items: center;
}

.report-type-selector__button {
  padding: 4px;
  line-height: 0;
  border-radius: 4px;
}
.report-type-selector__button:hover {
  background-color: #E6EBED;
}
.report-type-selector__button img {
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}
.report-type-selector__button h2 {
  display: inline-block;
  vertical-align: middle;
}

.report-type-selector__active {
  background-color: #FFD200 !important;
}

.report-builder__close {
  margin-left: 8px;
}

.report-builder__help {
  cursor: pointer;
  color: #989898;
}

.report-builder__generate {
  font-weight: 700;
  padding: 10px 16px 10px 64px;
  border-radius: 0;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 0;
}

.report-builder__generate-icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  background-color: rgba(0, 0, 0, 0.3);
}

.report-list {
  position: relative;
}

.report-list__create {
  position: absolute;
  top: 0;
  right: 0;
}

.highlight {
  overflow: visible !important;
  z-index: 100;
  position: relative;
  outline: 5000px rgba(0, 0, 0, 0.8) solid;
}

.highlight__content {
  position: absolute;
  left: 0;
  color: #fff;
  font-size: 16px;
}

.highlight__content--right {
  left: auto;
  right: 0;
  text-align: right;
}

.report__custom-banner {
  display: inline-block;
  position: relative;
  border: 1px solid #989898;
}
.report__custom-banner img {
  max-height: 100px;
  margin: 0;
  display: block;
}

.report__custom-banner-remove {
  position: absolute;
  top: 5px;
  right: 5px;
}

/**
    Channel Keys    
*/
.channel-keys__title {
  color: #989898;
  font-weight: bold;
}

.channel-keys__item {
  display: inline-block;
  margin-right: 10px;
}

body {
  background-color: rgba(255, 240, 255, 0.5882352941);
  overflow-y: scroll;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #CCCCCC #F2F2F2;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #F2F2F2;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: #CCCCCC;
  border-radius: 20px;
  border: 3px solid #CCCCCC;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.printarea__logo {
  display: none;
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.recharts-surface {
  border-radius: 5px;
}

.dark-input::placeholder {
  color: white;
  opacity: 1;
  /* Firefox */
}
.dark-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}
.dark-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}

@media print {
  body {
    visibility: hidden;
  }

  .printarea {
    position: absolute;
    left: 0;
    top: 0;
    visibility: visible;
    width: 100%;
  }

  .printarea__logo {
    display: block;
  }
}
.stat-group {
  border: 1px solid #E6EBED;
  position: relative;
  border-radius: 5px;
}

.stat-group__title {
  z-index: 12;
  margin: auto;
  text-align: center;
  padding: 3px 12px;
  display: inline-block;
  position: absolute;
  left: 10px;
  transform: translateY(-50%);
  background-color: #5E6172;
  color: #ffffff;
  border-radius: 5px;
}

.stat-group__content {
  text-align: center;
  background-color: #ffffff;
  padding: 30px 15px 15px;
  height: 100%;
  border-radius: 5px;
}

.stat-group__help {
  position: absolute;
  right: 0;
  top: 0;
}

.loader-mini {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
